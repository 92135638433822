.second{
    position:absolute;
    left:0;
    top:0;
    display:block;
    background:#09af77;
    color:white;
    border-radius: 5px;
    text-align:center;
    height:30px;
    width:180px;
    opacity:0;
    line-height:30px
}
.span-width-animation-parent{
    position:relative;
    width:180px
}
.span-width-animation{
    position:absolute;
    left:0;
    top:0;
    display:block;
    background:#09af77;
    width:0;
    text-align:center;
    height:30px
}
.span-width-animation-parent:hover .button-arrow{
    -webkit-animation: rightAnimation 0.2s linear;
    -webkit-animation-fill-mode: forwards;
    animation-delay:0.1s;
}
@-webkit-keyframes widthAnimation {
    0% {
        width: 80%;
    }
    100% {
        width: 100%;
    }
}
@-webkit-keyframes rightAnimation {
    0% {
        right: 17px;
        visibility:visible;

    }
    100% {
        right: 10px;
        visibility:visible;

    }
}
.button-arrow{
    position:absolute;
    display:inline-block;
    right:360px;
    top:5px;
}
.type1 {
    position: relative;
    padding: 14px 2px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    margin: 0 23px;
    font-weight: normal;
    font-size: 15px;
    line-height: 2px;
    background:#09af77;
    color:white;
    border-radius: 5px;
}
.header-nav-item:hover>span{
    color: #670F7A;
}
.header-nav-item:hover>svg{
    transform: rotate(-180deg);
}
.header-nav-item:hover .expended-nav-item{
    display: block;
}
.link-item{
    color: #262628;
}
.link-item:hover{
    color: #670F7A;
}
.link-item-svg{
    display: none;
}
.link-item:hover>svg{
    display: block;
}
.nav-item:hover >div>svg{
    display: block;
}
.nav-item:hover .nav-item-hidden{
    height: fit-content;
    transition:height 1s ;
}
